'use client';

import {
  RefreshChart,
  RefreshDocument,
  RefreshPlayIcon,
} from '../../../../svg';
import {
  ArrowSpan,
  ButtonLinkVideo,
  LinkArrowContainer,
  LinkContainer,
  Modal,
  ModalCloseButton,
  ModalDescription,
  ModalImage,
  ModalImageContainer,
  ModalList,
  ModalListItem,
  ModalListItemChevron,
  ModalListTitle,
  ModalSidebarList,
  ModalSidebarListTitle,
  ModalTextContainer,
  ModalTextMain,
  ModalTextSidebar,
  ModalTitle,
  ProductLink,
  RightArrowButton,
  RightArrowLink,
  SearchAdvisorButton,
  VideoLink,
} from './modal-card-section.style';
import { ModalCardSectionCard } from './modal-card-section.types';
import { useBreakpoint } from '@exploration/ui-utils';
import { Portal } from '@radix-ui/react-portal';
import { Close } from '@thrivent/midwest-web';
import { sanitize } from 'isomorphic-dompurify';
import { Fragment, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const buildDefaultModal = (
  card: ModalCardSectionCard,
  resetModal: () => void,
  modalContent?: string,
) => {
  return (
    <>
      <ModalImageContainer>
        <ModalImage
          {...card.modalImage}
          height={undefined}
          width={undefined}
          fill
        ></ModalImage>
      </ModalImageContainer>
      <ModalTextContainer>
        <ModalTextMain>
          <ModalTitle as="h3">{card.title}</ModalTitle>
          <ModalDescription as="p">
            {ReactHtmlParser(sanitize(card.description || ''))}
          </ModalDescription>
          {!!card?.listItems?.length && (
            <>
              <ModalListTitle as="h4">{card.listTitle}</ModalListTitle>
              <ModalList as="ul">
                {card.listItems.map((listItem) => (
                  <ModalListItem key={listItem} as="li">
                    <ModalListItemChevron />
                    {ReactHtmlParser(sanitize(listItem || ''))}
                  </ModalListItem>
                ))}
              </ModalList>
            </>
          )}
          <SearchAdvisorButton
            id={`${card.id}-modal-main-link`}
            href="https://local.thrivent.com"
          >
            <ArrowSpan>
              Find financial advisors
              <RightArrowButton />
            </ArrowSpan>
          </SearchAdvisorButton>
        </ModalTextMain>
        <ModalTextSidebar>
          {!!card?.sidebarTitle && !!card?.sidebarLinks?.length && (
            <ModalSidebarListTitle as="h4">
              {card.sidebarTitle}
            </ModalSidebarListTitle>
          )}
          {!!card?.sidebarLinks?.length && (
            <ModalSidebarList>
              {card.sidebarLinks.map((sidebarLink, i) => {
                return (
                  <LinkContainer key={sidebarLink.href ?? sidebarLink.videoId}>
                    {sidebarLink.type === 'video' ? (
                      <ButtonLinkVideo
                        titleText={sidebarLink.text}
                        videoId={sidebarLink?.videoId ?? ''}
                        linkComponent={
                          <VideoLink
                            data-analytics-id={`${card.id}-modal-link-${i}`}
                          >
                            <RefreshPlayIcon />
                            <LinkArrowContainer>
                              {sidebarLink.text}
                              <RightArrowLink />
                            </LinkArrowContainer>
                          </VideoLink>
                        }
                      />
                    ) : (
                      <ProductLink
                        href={sidebarLink.href}
                        type="navigational"
                        data-analytics-id={`${card.id}-modal-link-${i}`}
                        onClick={() => resetModal()}
                      >
                        {sidebarLink.icon === 'document' && <RefreshDocument />}
                        {sidebarLink.icon === 'chart' && <RefreshChart />}
                        <LinkArrowContainer onClick={() => resetModal()}>
                          {sidebarLink.text}
                          <RightArrowLink />
                        </LinkArrowContainer>
                      </ProductLink>
                    )}
                  </LinkContainer>
                );
              })}
            </ModalSidebarList>
          )}
        </ModalTextSidebar>
      </ModalTextContainer>
    </>
  );
};

// --------------------------------------------------------------------------

export const ModalCardSectionModal = ({
  className = '',
  card,
  resetModal,
  open,
  modalContent,
}: {
  className?: string;
  card: ModalCardSectionCard;
  resetModal: () => void;
  open: boolean;
  modalContent?: string;
}) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const bp = useBreakpoint();
  const { midwestColors: colors } = useTheme();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = bp === 'tablet' ? 'hidden' : 'unset';
      modalRef?.current?.show();
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [bp, open]);

  const PortalMobile = bp === 'tablet' ? Portal : Fragment;

  return (
    <PortalMobile>
      <Modal
        className={`${className} ${open ? 'open' : ''}`}
        ref={modalRef}
        role="dialog"
        open={open}
        data-analytics-name={`${card.id}-modal`}
        onBlur={(e) => {
          if (
            e.relatedTarget !== e.currentTarget &&
            !e.currentTarget.contains(e.relatedTarget) &&
            document.hasFocus()
          ) {
            resetModal();
          }
        }}
      >
        {buildDefaultModal(card, resetModal, modalContent)}
        <ModalCloseButton
          onClick={() => resetModal()}
          aria-label="Close Modal"
          data-analytics-id={`${card.id}-modal-close`}
        >
          <Close fill={colors.black} />
        </ModalCloseButton>
      </Modal>
    </PortalMobile>
  );
};
